<template>
    <div>
        <div v-if="$vuetify.breakpoint.mobile">
            <v-row  v-for="item in object.data" :key="item.id">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card
                        outlined
                        class="pa-2"
                    >
                        <v-row dense>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <v-avatar
                                    class="ma-0"
                                    color="#BDBDBD"
                                    tile
                                    width="100%"
                                    height="200px"
                                >
                                    <v-img 
                                        @click.prevent="showCarousel(item)"
                                        v-if="item.photos_total && item.photos_total.length > 0"
                                        :src="item.photos_total[0] | urlUpload"
                                        :lazy-src="item.photos_total[0] | urlUpload"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                            >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                    <v-icon v-else large dark>
                                        mdi-camera 
                                    </v-icon>
                                </v-avatar>

                            </v-col>    
                        </v-row>
                        <v-row dense>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <span>
                                    <v-chip
                                        label
                                        small
                                        color="blue lighten-5" 
                                    >
                                    <span style="font-size:12px">COD: {{ item.codigo }}</span> 
                                    </v-chip>   
                                </span>
                                <span class="ml-2">
                                    <v-chip
                                        :color="item.status | statusProperty"
                                        label
                                        small
                                    >
                                        {{ item.status }}
                                    </v-chip> 
                                </span>
                                
                                <span class="ml-2">
                                    <span  style="font-size:12px">
                                        {{ item.tipo_imovel }}
                                        <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span>
                                    </span>
                                </span>
                            </v-col>  
                        </v-row> 
                        <v-row dense>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <span  style="font-size:12px">
                                {{ item.endereco }}
                                <span v-if="item.numero">, {{ item.numero }} </span>
                                <span v-if="item.apartamento"> - Apto: {{ item.apartamento }} </span>
                                <span v-if="item.andar">  - {{ item.andar }} andar </span>
                                <span v-if="item.tower && item.tower.nome != ''"> - Torre: {{ item.tower.nome }} </span>
                                <span v-if="item.complemento"> - Compl: {{ item.complemento }} </span>
                                <span v-if="item.bairro">  - {{ item.bairro }} </span>
                                <span v-if="item.cidade">  - {{ item.cidade }} </span>
                                <span v-if="item.estado">  - {{ item.estado }} </span>
                                <span v-if="item.cep">  - {{ item.cep | VMask('#####-###') }} </span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span v-if="item.area">
                                <v-chip
                                    label
                                    small
                                    color="blue-grey lighten-5" 
                                >
                                   <span>Área {{ item.area }} m²</span> 
                                </v-chip>
                            </span>
                            <span class="ml-2" v-if="item.dormitorios">
                                <v-chip
                                    label
                                    small
                                    color="blue-grey lighten-5" 
                                >
                                   <span>{{ item.dormitorios }} dorm(s)</span> 
                                </v-chip>
                            </span>
                            <span class="ml-2" v-if="item.suites">
                                <v-chip
                                    label
                                    small
                                    color="blue-grey lighten-5 ma-1" 
                                >
                                   <span>{{ item.suites }} suite(s)</span> 
                                </v-chip>
                            </span>
                            <span class="ml-2" v-if="item.banheiros">
                                <v-chip
                                    label
                                    small
                                    color="blue-grey lighten-5" 
                                >
                                   <span>{{ item.banheiros }} wc(s)</span> 
                                </v-chip>
                            </span>
                            <span class="ml-2" v-if="item.vagas">
                                <v-chip
                                    label
                                    small
                                    color="blue-grey lighten-5" 
                                >
                                   <span>{{ item.vagas }} vg(s)</span> 
                                </v-chip>
                            </span>
                            </v-col>  
                        </v-row> 
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <span class="text-h7 black--text">  
                                    DT CAD: 
                                    <span v-if="item.created_at"> {{ formatDate(item.created_at) }}</span>
                                </span>
                                <span class="ml-2 text-h7 black--text">  
                                    DT AT.: 
                                    <span v-if="item.data_atualizacao"> {{ item.data_atualizacao }}</span>
                                </span>  
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col class="text-right" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <span v-if="item.valor_venda && item.valor_venda > 0">Venda {{ item.valor_venda | toCurrency }}</span> 
                                <span v-if="item.valor_venda && item.valor_venda > 0" class="mr-2"></span>
                                
                                <span v-if="item.valor_locacao && item.valor_locacao > 0">Locaçāo {{ item.valor_locacao | toCurrency }}</span> 
                                <span v-if="item.valor_locacao && item.valor_locacao > 0" class="mr-2"></span>

                                <div v-if="item.valor_condominio && item.valor_condominio > 0" class="mr-2">
                                    <span style="font-size:11px">Cond. {{ item.valor_condominio | toCurrency }}</span> 
                                </div>
                                <div v-if="item.valor_iptu && item.valor_iptu > 0" class="mr-2">
                                    <span style="font-size:11px">IPTU {{ item.valor_iptu | toCurrency }}</span> 
                                </div>
                                <div v-if="item.valor_locacao && item.valor_locacao > 0" class="mr-2">
                                    <span >TOTAL {{ setTotal(item) | toCurrency }}</span> 
                                </div>
                            </v-col>
                        </v-row> 

                        <v-row dense>
                            <v-col class="text-center" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <v-card-actions class="text-center">
                                    <v-spacer></v-spacer>
                                    <favorite-button-component v-if="$route.name == 'admin.properties.index'" :object="item"></favorite-button-component>
                                    
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="showItem(item.id)" class="ml-2">
                                                <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar</span>
                                    </v-tooltip>
                                    
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.prevent="editItem(item.id)" class="ml-2">
                                                <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>

                                    <span v-if="tenant[0].json_params && tenant[0].json_params.show_trash && tenant[0].json_params.show_trash == 'false'">

                                    </span>
                                    <span v-else>
                                        <v-tooltip top v-if="$route.name == 'admin.properties.index' && me.profile == 'Master'">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click.prevent="confirmDestroy(item)" class="ml-2">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Delete</span>
                                        </v-tooltip>
                                    </span>

                                    <button-share-component :object="item"></button-share-component>
                                </v-card-actions> 
                            </v-col>    
                        </v-row>

                    </v-card>
                </v-col>    
            </v-row>
            <v-row v-if="object.meta && object.meta.total > 0">
                <v-col  cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span v-for="item in object.meta.links" :key="item.label">
                        <v-btn
                            v-if="item.label == '&laquo; Previous' && item.url != null"
                            class="mt-2 mb-3" 
                            color="error"
                            @click="linkPagination(object.meta.current_page - 1)"
                            block
                        >
                            <v-icon left>
                                mdi-skip-previous
                            </v-icon>
                            Anterior
                        </v-btn>

                        <v-btn
                            v-if="item.label == 'Next &raquo;' && item.url != null" 
                            color="primary"
                            class="mt-2 mb-3"
                            @click="linkPagination(object.meta.current_page + 1)"
                            block
                        >
                            Próximo
                            <v-icon left class="ma-1">
                                mdi-skip-next
                            </v-icon>
                        </v-btn>
                    </span>   
                </v-col> 
            </v-row>    



            <!--v-row v-if="object.meta && object.meta.total > 0">
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-btn
                        v-if="object.meta.to > 1 && object.meta.total > this.options.itemsPerPage"
                        depressed
                        class="mt-2 mb-3" 
                        color="error"
                        @click="linkPagination(object.meta.to - 1)"
                        block
                    >
                        <v-icon left>
                            mdi-skip-previous
                        </v-icon>
                        Anterior
                    </v-btn>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-btn
                        v-if="object.meta.to < object.meta.total"
                        depressed
                        color="primary"
                        class="mt-2 mb-3"
                        @click="linkPagination(object.meta.to + 1)"
                        block
                    >
                        Próximo
                        <v-icon left class="ma-1">
                            mdi-skip-next
                        </v-icon>
                    </v-btn>
                </v-col>    
            </v-row-->
        </div>

<!-- PC -->
        <v-row v-if="!$vuetify.breakpoint.mobile"> 
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                <v-data-table
                    :headers="headers"
                    :items="object.data"
                    :options.sync="options"
                    :server-items-length="object.meta && object.meta.total"
                    class="elevation-1 table-striped caption"
                    :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                >
                    
                    <template v-slot:[`item.foto`]="{ item }">
                        <v-avatar
                            class="ma-2"
                            color="#BDBDBD"
                            tile
                            width="267px"
                            height="200px"
                        >
                            <v-img 
                                @click.prevent="showCarousel(item)"
                                v-if="item.photos_total && item.photos_total.length > 0"
                                :src="item.photos_total[0] | urlUpload"
                                :lazy-src="item.photos_total[0] | urlUpload"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-icon v-else large dark>
                                mdi-camera 
                            </v-icon>
                        </v-avatar>
                    </template>
                    
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            :color="item.status | statusProperty"
                            label
                            small
                        >
                            {{ item.status }}
                        </v-chip> 
                    </template>

                    <template v-slot:[`item.detalhes`]="{ item }">
                        <div>    
                            <div class="mb-2 mt-2">
                                <span>
                                    <v-chip
                                        color="blue"
                                        label
                                        text-color="white"
                                        class="font-weight-medium text-h7"
                                    >
                                    COD: {{ item.codigo }} 
                                    </v-chip>   
                                </span>
                                <span class="ml-2">
                                    <v-chip
                                        :color="item.status | statusProperty"
                                        label
                                        text-color="black"
                                        class="font-weight-medium text-h7"
                                    >
                                        {{ item.status }}
                                    </v-chip> 
                                </span>
                                
                                <span class="ml-2 text-h7 black--text font-weight-medium">
                                    {{ item.tipo_imovel }}
                                    <span v-if="(showSubTipoImovel(item) != '')"> 
                                        ( {{showSubTipoImovel(item)}} )
                                    </span>
                                </span>   

                                <span class="ml-3 text-right text-h7 black--text font-weight-medium">  
                                    DT CAD.: 
                                    <span v-if="item.created_at"> {{ formatDate(item.created_at) }}</span>
                                </span>
                                <span class="ml-2 text-right text-h7 black--text font-weight-medium">  
                                    DT AT.: 
                                    <span v-if="item.data_atualizacao"> {{ item.data_atualizacao }}</span>
                                </span>     
                            </div>

                            <div class="text-subtitle-1 font-weight-medium">
                                {{ item.endereco }}
                                <span v-if="item.numero">, {{ item.numero }} </span>
                                <span v-if="item.apartamento"> - Apto: {{ item.apartamento }} </span>
                                <span v-if="item.andar">  - {{ item.andar }} andar </span>
                                <span v-if="item.tower && item.tower.nome != ''"> - Torre: {{ item.tower.nome }} </span>
                                <span v-if="item.complemento"> - Compl: {{ item.complemento }} </span>
                                <span v-if="item.bairro">  - {{ item.bairro }} </span>
                                <span v-if="item.cidade">  - {{ item.cidade }} </span>
                                <span v-if="item.estado">  - {{ item.estado }} </span>
                                <span v-if="item.cep">  - {{ item.cep | VMask('#####-###') }} </span>
                            </div> 
                            <div class="mt-3">
                                <span v-if="item.area">
                                    <v-chip
                                        label
                                        outlined
                                        color="black"
                                        dark
                                        class="mt-1"
                                    >
                                    <span>{{ item.tipo_imovel | labelArea('area') }} {{ item.area }} m²</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.area_total">
                                    <v-chip
                                        label
                                        outlined
                                        color="black"
                                        dark
                                        class="mt-1"
                                    >
                                    <span>{{ item.tipo_imovel | labelArea('area_total') }} {{ item.area_total }} m²</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.area_construida_terreno">
                                    <v-chip
                                        label
                                        outlined
                                        color="black"
                                        dark
                                        class="mt-1"
                                    >
                                    <span>A. const. terr. {{ item.area_construida_terreno }} m²</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.dormitorios">
                                    <v-chip
                                        label 
                                        outlined
                                        color="black"
                                        dark
                                        class="mt-1"
                                    >
                                    <span>{{ item.dormitorios }} dorm(s)</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.suites">
                                    <v-chip
                                        label
                                        outlined
                                        color="black"
                                        dark
                                        class="mt-1"
                                    >
                                    <span>{{ item.suites }} suite(s)</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.banheiros">
                                    <v-chip
                                        label
                                        outlined
                                        color="black"
                                        dark
                                        class="mt-1"
                                    >
                                    <span>{{ item.banheiros }} banheiro(s)</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.vagas">
                                    <v-chip
                                        label
                                        outlined
                                        color="black"
                                        dark
                                        class="mt-1"
                                    >
                                    <span>{{ item.vagas }} vaga(s)</span> 
                                    </v-chip>
                                </span>
                            </div>

                            <span v-if="tenant[0].json_params && tenant[0].json_params.json_colaborators && tenant[0].json_params.json_colaborators && tenant[0].json_params.json_colaborators == 'true'">
                                <div v-if="item.json_colaborators && item.json_colaborators.length > 0" class="mt-3 font-weight-medium">
                                    Colaboradores: 
                                    <span v-for="(colab, index) in item.json_colaborators" :key="colab.id">
                                        {{ colab.name }}
                                        <span v-if="(index + 1) < item.json_colaborators.length">, </span>
                                    </span>
                                </div>  
                            </span>

                            <div class="mt-3 mb-3">
                                <v-row no-gutters>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div>
                                            <span v-if="item.valor_venda && item.valor_venda > 0" :class="$css_subtitle_2 + ' text-h6'">Venda {{ item.valor_venda | toCurrency }}</span> 
                                            <span class="ml-2" v-if="item.valor_locacao && item.valor_locacao > 0" :class="$css_subtitle_2 + ' text-h6'">Locaçāo {{ item.valor_locacao | toCurrency }}</span> 
                                            <span class="ml-2" v-if="item.valor_condominio && item.valor_condominio > 0" :class="$css_subtitle_2">Cond. {{ item.valor_condominio | toCurrency }}</span> 
                                            <span class="ml-2" v-if="item.valor_iptu && item.valor_iptu > 0"  :class="$css_subtitle_2">IPTU {{ item.valor_iptu | toCurrency }}</span> 
                                            <span class="ml-2" v-if="item.valor_locacao && item.valor_locacao > 0" :class="$css_subtitle_2">Total {{ setTotal(item) | toCurrency }}</span> 
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <div class="mt-1 mb-1">
                            <v-row dense>
                                <v-col cols="auto">
                                    <favorite-button-component v-if="$route.name == 'admin.properties.index'" :object="item" :outlined="true"></favorite-button-component>
                                </v-col>
                                 <v-col cols="auto">
                                    <button-share-component :object="item" :outlined="true"></button-share-component>
                                </v-col>
                                <v-col v-if="me.profile == 'Master' || me.profile == 'Gerente'" cols="auto">
                                     <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn :class="$css_button" depressed outlined @click.prevent="editPortal(item.id)">
                                                <v-icon left v-bind="attrs" v-on="on">mdi-hub</v-icon> Portal
                                            </v-btn>
                                        </template>
                                        <span>Portais de propaganda</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col class="text-right" cols="auto">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn :class="$css_button" depressed outlined @click.prevent="showCarousel(item)">
                                                <v-icon left v-bind="attrs" v-on="on">mdi-camera</v-icon> Foto
                                            </v-btn>
                                        </template>
                                        <span>Slide show</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="auto">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn :class="$css_button" block depressed outlined @click.prevent="showItem(item.id)">
                                                <v-icon left v-bind="attrs" v-on="on">mdi-eye</v-icon> FICHA
                                            </v-btn>
                                        </template>
                                        <span>Visualizar Ficha</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="auto">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn :class="$css_button" block depressed outlined @click.prevent="editItem(item.id)">
                                                <v-icon left v-bind="attrs" v-on="on">mdi-pencil</v-icon> EDITAR
                                            </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </v-col>
                               
                                <v-col cols="auto">
                                    <span v-if="tenant[0].json_params && tenant[0].json_params.show_trash && tenant[0].json_params.show_trash == 'false'">

                                    </span>
                                    <span v-else>
                                        <v-tooltip top v-if="me.profile == 'Master' || me.profile == 'Gerente'">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :class="$css_button" block depressed outlined @click.prevent="confirmDestroy(item)">
                                                    <v-icon left v-bind="attrs" v-on="on">mdi-delete</v-icon> EXCLUIR
                                                </v-btn>
                                            </template>
                                            <span>Excluir</span>
                                        </v-tooltip>
                                    </span>
                                </v-col>
                            </v-row>
                         </div>
                    </template>
                </v-data-table>
               
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialog.carousel"
            width="800"
            overlay-opacity="0.8"
        >
            <v-card class="d-print-none">
                <div class="text-right">
                    <v-btn icon text class="mr-8" @click="openCloseDialogCarousel()">
                        fechar <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <carousel-component :object="item" @callOpenCloseCarousel="openCloseDialogCarousel" :key="key.carousel"></carousel-component>
            </v-card> 
        </v-dialog>

    </div>
</template>

<script>
import ButtonShareComponent from '../../button_share/ButtonShareComponent.vue'
import CarouselComponent from '../../carousel/CarouselComponent.vue'
import FavoriteButtonComponent from '../../favoritebutton/FavoriteButtonComponent.vue'

export default {

    components: {
        ButtonShareComponent,
        CarouselComponent,
        FavoriteButtonComponent
    },

    props: {
        object: {},

        filters: {},
    }, 

    computed: {
        me () {
          return this.$store.state.auth.me
        },
        tenant() {
            return this.$store.state.tenants.items.data    
        },
    }, 

    watch: {
        options: {
            handler() {
                if(this.options.page > 0){
                    this.loadItems();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },

    },

    data () {
        return {
            params: '',
            
            key: {
                carousel: 1
            },
            dialog: {
                carousel: false,
            },

            item: {},

            media: '',

            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'FOTO', value: 'foto' , sortable: false},
                { text: 'DETALHES', value: 'detalhes' , sortable: false},  
            ],

            confirmDelete: {
                id: '',
                status: false,
                name: '',
                text: '',
                destroyfunction: '',
            },
        }    
    },

    methods: {
        loadItems(){
            this.params = this.filters 
            this.params.page = this.options.page
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            //console.log(this.params)
            this.$emit('callLoadItems', this.params)   
        },

        openCloseDialog() {
            this.dialog = !this.dialog
        },

        editItem(id){
            this.$store.commit('STORE_PROPERTIES', false)
            this.$emit('callEditItem', id)
        },

        editPortal(id){
            this.$emit('callEditPortal', id)
        },

        showItem(id){
            this.$emit('callShowItem', id)
        },

        confirmDestroy(item) {
            this.$snotify.error(`Tem certeza que deseja excluir esse registro?`, 'Confirmaçāo', {
                timeout: 10000,
                showProgressBar: true,
                closeоnClick: true,
                buttons: [
                    {text: 'Nāo', action: this.$snotify.remove()},
                    {text: 'Sim', action: () => {
                        this.destroy(item)
                        this.$snotify.remove()
                    }},
                ]
            })
        },

        destroy(item){
            this.params = this.filters 
            
            this.$store.dispatch('destroyProperty', item.id)
                        .then(() => {
                            this.$snotify.success(`Registro excluído com sucesso`, 'Exclusāo')

                            this.$emit('callLoadItems', this.params)
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
        },

        showCarousel(item){
            this.item = item
            this.openCloseDialogCarousel()
        },

        openCloseDialogCarousel(){
            this.dialog.carousel = !this.dialog.carousel
        },

        linkPagination(page){
            this.options.page = page 
            this.loadItems();
            window.scrollTo(0,0)
        }

    },
}
</script>
