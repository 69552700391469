<template>
    <div>
        <v-bottom-sheet>
            <template v-slot:activator="{ on, attrs }">

                <v-btn v-if="outlined" v-bind="attrs" v-on="on" :class="$css_button" depressed outlined>
                    <v-icon left >mdi-share-variant</v-icon> Compart.
                </v-btn>

                <v-icon v-else v-bind="attrs" v-on="on" >mdi-share-variant</v-icon>
                
            </template>
            <v-list>
                <v-subheader>Compartilhar {{ me.branch.url }}</v-subheader>
                <v-list-item
                    v-for="tile in items.share_items"
                    :key="tile.title"
                >
                    <v-list-item-avatar>
                        <v-avatar
                            size="32px"
                            tile
                            @click="setMedia(tile.value, object)"
                        >
                            
                            <img
                                :src="`img/share-buttons/${tile.img}`"
                                :alt="tile.title"
                            >
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-title @click="setMedia(tile.value, object)">{{ tile.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-bottom-sheet>

        <!-- Dialog -->
        <v-dialog 
            v-model="dialog.share" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Compartilhar anúncio - Código {{ object.codigo }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogShare()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <share-property-component :object="object" :media="media"></share-property-component>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

        <!-- Dialog -->
        <v-dialog 
            v-model="dialog.email" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Enviar anúncio por e-mail
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogEmail()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <send-property-mail :object="object"></send-property-mail>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->
    </div>
</template>

<script>
import { SHARE_ITEMS } from './config_button_share/config_button_share.js'
import SharePropertyComponent from '../shareproperty/SharePropertyComponent.vue'
import SendPropertyMail from '../favorites/partials/SendPropertyMail.vue'

export default {    

    components: {
       SharePropertyComponent,
       SendPropertyMail
    },

    props: {
        object: {
            require: true,
        },

        outlined: {
            require: false,
            default: false
        }
    }, 

    watch: {
       
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    data () {
        return {
            dialog: {
                email: false,
                share: false
            },

            media: '',

            items: {
                share_items: SHARE_ITEMS
            },
        }    
    },

    methods: {
        dialogEmail(){
            this.dialog.email = !this.dialog.email
        },

        dialogShare(){
            this.dialog.share = !this.dialog.share
        },


        setMedia(media, item){
            var copy = ''
            copy = this.me.branch.url + item.link
            if(media == 'email'){
                this.dialog.email = true
            }

            if(media == 'facebook' || media == 'whatsapp' || media == 'telegram'){
                this.media = media
                this.dialog.share = true
            }

            if(media == 'copy'){
                this.copyLink(copy)
            }
        },

        copyLink(link){
            navigator.clipboard.writeText(link);
            this.$snotify.success(`Link do imóvel copiado. Utilize o CTRL + V para colar`, 'Link do imovel')
        },
    },
}
</script>