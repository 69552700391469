<template>
    <div v-if="!object.id" class="mt-8">
        <main-component :object="item" @callLoadItem="loadItem" @callRedirectEditItem="redirectEditItem"></main-component>
    </div>
    <div v-else>
        <v-alert 
            v-if="property_items.meta && property_items.meta.total > 0"
            text
            dense
            color="warning"          
            icon="mdi-alert-outline"    
        >
            <span class="black--text mr-2">O sistema detectou imóvel(eis) no perfil desse cliente. Veja a lista abaixo </span>
        </v-alert>

        <v-tabs
            v-model="active_tab"
            show-arrows 
        >
            <v-tab href="#tab-1" class="caption"> Principal</v-tab>
            <v-tab href="#tab-2" class="caption"> Colaboradores </v-tab>
            <v-tab href="#tab-3" class="caption" @click="loadPropertyRelation"> Imóveis Relacionados </v-tab>
            <v-tab href="#tab-4" class="caption" @click="loadPromisseComments"> Histórico </v-tab>
            <v-tab v-if="item.finalidade == 'Alugar um imóvel' || item.finalidade == 'Comprar um imóvel'" href="#tab-5" class="caption"> Perfil de imóvel </v-tab>
            <v-tab v-if="item.finalidade == 'Alugar um imóvel' || item.finalidade == 'Comprar um imóvel'" href="#tab-6" class="caption"> Locais </v-tab>
            <v-tab href="#tab-7" class="caption"> Status </v-tab>
        </v-tabs>
    
        <v-tabs-items v-model="active_tab">
            <v-tab-item value="tab-1">
                <v-card flat class="pa-8">
                    <main-component :object="item" @callLoadItem="loadItem"></main-component>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
                <v-card flat class="pa-8">
                    <colaborator-component :object="colaborators" @callLoadItem="loadItem" ref="colaborators"></colaborator-component>
                </v-card>    
            </v-tab-item>
            <v-tab-item value="tab-3">
                <v-card flat class="pa-8">
                    <property-relation-component :object="property_relations" @callLoadItem="loadPropertyRelation" ref="property_relations"></property-relation-component>
                </v-card>    
            </v-tab-item>
            <v-tab-item value="tab-4">
                <v-card flat class="pa-8">
                    <comment-component :object="comments" @callLoadItem="loadPromisseComments" ref="property_relations"></comment-component>
                </v-card>    
            </v-tab-item>
            <v-tab-item value="tab-5">
                <v-card flat class="pa-8">
                    <property-profile-component :object="item.property_profiles" @callLoadItem="loadItem" :fac_id="item.id" ref="property_profile"></property-profile-component>
                </v-card>    
            </v-tab-item>
            <v-tab-item value="tab-6">
                <v-card flat class="pa-8">
                    <place-antigo-component :object="places" @callLoadItem="loadItem" ref="place"></place-antigo-component>
                </v-card>    
            </v-tab-item>
            <v-tab-item value="tab-7">
                <v-card flat class="pa-8">
                    <status-component :object="item" @callLoadItem="loadItem"></status-component>
                </v-card>    
            </v-tab-item>
        </v-tabs-items>

        <v-row class="mt-3" v-if="property_items.meta && property_items.meta.total > 0">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="property_items.data"
                        :options.sync="options"
                        :server-items-length="property_items.meta && property_items.meta.total"
                        class="elevation-1 table-striped caption"
                        :footer-props="{
                            'items-per-page-text':'Itens por página',
                            'items-per-page-options': [5, 10],
                            'items-per-page-all-text': '',
                            'page-text': '{0}-{1} de {2}'
                        }"
                    >
                        <template v-slot:[`item.photo`]="{ item }">
                            <v-avatar
                                class="ma-0"
                                color="#BDBDBD"
                                tile
                                width="200px"
                                height="150px"
                            >
                                <v-img 
                                    v-if="item.photos_total && item.photos_total.length > 0"
                                    :src="item.photos_total[0] | urlUpload"
                                    :lazy-src="item.photos_total[0] | urlUpload"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                                <v-icon v-else large dark>
                                    mdi-camera 
                                </v-icon>
                            </v-avatar>
                        </template>

                        <template v-slot:[`item.description`]="{ item }">
                           <div class="mb-2 mt-2">
                                <span>
                                    <v-chip
                                        label
                                        small
                                        color="blue lighten-5" 
                                    >
                                    <span style="font-size:12px">{{ item.codigo }}</span> 
                                    </v-chip>   
                                </span>
                                <span class="ml-2">
                                    <v-chip
                                        :color="item.status | statusProperty"
                                        label
                                        small
                                    >
                                        {{ item.status }}
                                    </v-chip> 
                                </span>
                                
                                <span class="ml-2 black--text font-weight-medium">
                                    {{ item.tipo_imovel }}
                                    <span v-if="item.sub_tipo_imovel"> ({{ item.sub_tipo_imovel }})</span>
                                </span>
                            </div>
                            <div>
                                {{ item.endereco }}
                                <span v-if="item.numero">, {{ item.numero }} </span>
                                <span v-if="item.apartamento"> - Apto: {{ item.apartamento }} </span>
                                <span v-if="item.andar">  - {{ item.andar }} andar </span>
                                <span v-if="item.tower && item.tower.nome != ''"> - Torre: {{ item.tower.nome }} </span>
                                <span v-if="item.complemento"> - Compl: {{ item.complemento }} </span>
                                <span v-if="item.bairro">  - {{ item.bairro }} </span>
                                <span v-if="item.cidade">  - {{ item.cidade }} </span>
                                <span v-if="item.estado">  - {{ item.estado }} </span>
                                <span v-if="item.cep">  - {{ item.cep | VMask('#####-###') }} </span>
                            </div> 
                            <div class="mt-3">
                                <span v-if="item.area">
                                    <v-chip
                                        label
                                        small
                                        color="blue-grey lighten-5 ma-1" 
                                    >
                                    <span>Área {{ item.area }} m²</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.dormitorios">
                                    <v-chip
                                        label
                                        small
                                        color="blue-grey lighten-5 ma-1" 
                                    >
                                    <span>{{ item.dormitorios }} dorm</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.banheiros">
                                    <v-chip
                                        label
                                        small
                                        color="blue-grey lighten-5 ma-1" 
                                    >
                                    <span>{{ item.banheiros }} banheiros</span> 
                                    </v-chip>
                                </span>
                                <span class="ml-2" v-if="item.vagas">
                                    <v-chip
                                        label
                                        small
                                        color="blue-grey lighten-5 ma-1" 
                                    >
                                    <span>{{ item.vagas }} vagas</span> 
                                    </v-chip>
                                </span>
                            </div>

                            <div class="mt-3">
                                <v-row dense>
                                    <v-col v-if="item.valor_venda && item.valor_venda > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_sub_value">Venda {{ item.valor_venda | toCurrency }}</span> 
                                    </v-col>
                                    <v-col v-if="item.valor_locacao && item.valor_locacao > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_sub_value">Locaçāo {{ item.valor_locacao | toCurrency }}</span> 
                                    </v-col>
                                    <v-col class="pa-3" v-if="item.valor_condominio && item.valor_condominio > 0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <span :class="$css_sub_value">Condomínio {{ item.valor_condominio | toCurrency }}</span> 
                                    </v-col>
                                </v-row>
                            </div>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="showItem(item.id)" class="ml-2">
                                            <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Visualizar</span>
                                </v-tooltip>

                                <button-share-component :object="item"></button-share-component>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row> 

        <!-- Dialog -->
        <v-dialog 
            v-if="property && property != ''"
            v-model="dialog.show" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="property.codigo">Código {{property.codigo}} - {{ property.tipo_imovel }} <span v-if="property.sub_tipo_imovel"> ({{ property.sub_tipo_imovel }})</span> </span>
                    <v-spacer></v-spacer>
                    
                    <!--button-share-component class="mr-4" :object="property"></button-share-component-->
                    
                    <v-btn icon @click="openCloseDialog('show')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <show-component :object="property" :permissions="permissions"></show-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

    </div>    
</template>


<script>
import MainComponent from './MainComponent.vue'
import ColaboratorComponent from '@/components/colaborators/ColaboratorComponent.vue'
import PropertyRelationComponent from '@/components/property_relations/PropertyRelationComponent.vue'
import CommentComponent from '@/components/comments/CommentComponent.vue'
import PropertyProfileComponent from '@/components/propertyprofiles/PropertyProfileComponent.vue'
import StatusComponent from './StatusComponent.vue'
import ShowComponent from '@/components/properties/show/ShowComponent.vue'
import ButtonShareComponent from '../../button_share/ButtonShareComponent.vue'
import PlaceAntigoComponent from '@/components/places/PlaceAntigoComponent.vue'

export default {
    created() {
        this.setTab()
        this.setObject()
    },

    components: {
        MainComponent,
        ColaboratorComponent,
        PropertyRelationComponent,
        CommentComponent,
        PropertyProfileComponent,
        StatusComponent,
        ShowComponent,
        PlaceAntigoComponent,
        ButtonShareComponent
    },

    props: {
        object: {
            require: false,
        },
    }, 

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    watch: {
        object () {   
            if(this.item.id != this.object.id){
                this.setTab()
            }
            this.setObject()
        },

        options: {
            handler() {
                if(this.options.page >= 1){
                    this.checkPropertyProfile();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },
    },

    data () {
        return {
            active_tab: 'tab-1',
            item: {},
            property: {},
            property_items: [],
            total_properties: 0,
            dialog: {
                show: false
            },
            permissions: '',

            colaborators: {
                model: "facs",
                descriptions: ['Atendimento','Recepçāo','Gerente'],
                item_id: "",
                items: []     
            },

            property_relations: {
                model: "facs",
                item_id: "",
                items: []   
            },
            
            comments: {
                model: "facs",
                item_id: "",
                items: []   
            },

            places: {
                model: "facs",
                item_id: "",
                cidade: "",
                estado: "",
                items: []   
            },

            params_check_properties: {
                multiple_status: ['Ativo','Desatualizado'],
                places: [],
                multiple_setor: []
            },

            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'FOTO', align: 'start', value: 'photo', },
                { text: 'DESCRIÇĀO', align: 'start', value: 'description', },
                { text: 'AÇĀO', value: 'actions', sortable: false },
            ],

        }
    },

    methods: {
        redirectEditItem(id){
            this.$emit('callRedirectEditItem', id)
        },

        loadItem(id){
            this.$emit('callLoadItem', id)
        },
        
        setTab(){
            this.active_tab = 'tab-1'
        },

        showItem (id) {    
            this.$store.dispatch('loadProperty', id) 
                        .then(response => {
                            this.property = response.data
                            this.dialog.show = true
                            this.checkColaborator()
                        })                          
        },

        checkColaborator(){
            if(this.me.profile != 'Master' && this.me.profile != 'Gerente'){
                if(this.property && this.property.colaborators.length > 0){
                    var access = false
                    this.property.colaborators.forEach(i => {
                        if(i.user_id == this.me.id){
                            access = true
                        }
                    })
                    this.permissions.colaborator = access
                }
            }
        },

        setObject(){
            this.item = this.object
            this.total_properties = 0
            this.property_items = '',

            // colaborators
            this.colaborators.item_id = this.object.id
            this.colaborators.items = this.object.colaborators

            // property_relations
            this.property_relations.item_id = this.object.id
            this.property_relations.items = this.object.property_relations

            // comments
            this.comments.item_id = this.object.id
            this.comments.items = this.object.comments

            // places
            this.places.item_id = this.object.id
            this.places.estado = this.me.branch.estado
            this.places.items = this.object.places    

            if(this.item && (this.item.finalidade == 'Alugar um imóvel' || this.item.finalidade == 'Comprar um imóvel')){
                this.checkPropertyProfile()
            }
        },

        checkPropertyProfile(){

            if(this.item.finalidade == 'Alugar um imóvel'){
                this.params_check_properties.multiple_setor.push('Locação')
            }else{
                this.params_check_properties.multiple_setor.push('Venda')
            }

            if(this.item.property_profiles && this.item.property_profiles.length > 0){
                //console.log(this.item.property_profiles[0])
                const profile = this.item.property_profiles[0]  
                if(profile.tipo_imovel && profile.tipo_imovel != ''){
                    this.params_check_properties.multiple_tipo_imovel = profile.tipo_imovel.split(',')
                }  
                if(profile.sub_tipo_imovel && profile.sub_tipo_imovel != ''){
                    this.params_check_properties.multiple_sub_tipo_imovel = profile.sub_tipo_imovel.split(',')
                }
                if(profile.dormitorios && profile.dormitorios > 0){
                    this.params_check_properties.dormitorios_from = profile.dormitorios 
                }
                if(profile.banheiros && profile.banheiros > 0){
                    this.params_check_properties.banheiros_from = profile.banheiros 
                }
                if(profile.vagas && profile.vagas > 0){
                    this.params_check_properties.vagas_from = profile.vagas 
                }
                if(profile.valor_minimo && profile.valor_minimo > 0){
                    if(this.item.finalidade == 'Alugar um imóvel'){
                        this.params_check_properties.valor_locacao_from = profile.valor_minimo
                    }else{
                        this.params_check_properties.valor_venda_from = profile.valor_minimo
                    }
                }
                if(profile.valor_maximo && profile.valor_maximo > 0){
                    if(this.item.finalidade == 'Alugar um imóvel'){
                        this.params_check_properties.valor_locacao_to = profile.valor_maximo
                    }else{
                        this.params_check_properties.valor_venda_to = profile.valor_maximo
                    }
                }
                if(profile.area && profile.area > 0){
                    this.params_check_properties.area_from = profile.area 
                }
                if(profile.em_condominio && profile.em_condominio != ''){
                    this.params_check_properties.area_from = profile.em_condominio
                }
                if(profile.aceita_permuta && profile.aceita_permuta != ''){
                    this.params_check_properties.aceita_permuta = profile.aceita_permuta
                }

                this.params_check_properties.places = []
                if(this.item.places && this.item.places.length > 0){
                    this.item.places.forEach(element => {
                        var local = ''
                        local = element.distrito + ',' + element.cidade + ',' + element.estado_sigla
                        this.params_check_properties.places.push(local)
                    });
                }
            
                this.params_check_properties.page = this.options.page
                if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                    this.params_check_properties.paginate = this.options.itemsPerPage
                }
                
                this.params_check_properties.limit = 100
               
                this.$store.dispatch('loadPromisseProperties', this.params_check_properties) 
                            .then(response => {
                                this.property_items = response
                            })
            }
        },

        setFirstPhoto(property_photos, condominium_photos){
            var photo = []
            if(property_photos && property_photos != ''){
                photo = property_photos
            }else{
                if(condominium_photos && condominium_photos != ''){
                    photo = condominium_photos
                }
            }
            return photo
        },

        openCloseDialog(window){
            if(window == 'show'){
                this.dialog.show = !this.dialog.show 
            }
        },

        loadPropertyRelation(){
            this.$store.dispatch('loadPropertyRelations', this.object.id)
                        .then(response => {
                            this.property_relations.items = response.data
                        })
                        .catch(error => {
                            console.log(error)
                        })
        },

        loadPromisseComments(){
            const params = {}
            params.item_id = this.object.id
            this.$store.dispatch('loadPromisseComments', params)
                        .then(response => {
                            this.comments.items = response.data
                        })
                        .catch(error => {
                            console.log(error)
                        })
        }
        
    },
}
</script>

<style scoped>

</style>