<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        class="d-print-none"
    >
        <menu-navigation-component></menu-navigation-component>
    </v-navigation-drawer>

    
    <v-app-bar
        class="d-print-none"
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="#1063b6"
        dark
    >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title
            style="width: 300px"
            class="ml-0 pl-4 sm-text-center"
        >
            <span class="text-center">
                <v-avatar tile size="140">
                    <v-img 
                        class="center"
                        src="img/logo_inner.png?3"
                        lazy-src="img/logo_inner.png?3"
                    >
                    </v-img>
                </v-avatar>
            </span>
        </v-toolbar-title>

        <v-select
            v-model="paramshome.type"
            :items="items.search_types"
            clearable
            flat
            solo-inverted
            :class="$css_field + ' mt-7 hidden-sm-and-down'"
        >
            <template v-slot:label>
                <span :class="$css_field">Modulo</span>
            </template>
        </v-select>

        <v-text-field
            v-model="paramshome.filter_home"
            clearable
            flat
            solo-inverted
            :class="$css_field + ' ml-2 mt-7 hidden-sm-and-down'"
            @keydown.enter="searchHome"
        >
            <template v-slot:label>
                <span :class="$css_field">
                    <span v-if="paramshome.type == 'FAC'">Código da FAC ou parte do nome</span>
                    <span v-if="paramshome.type == 'Imóvel'">Código do imóvel ou parte do endereço</span>
                    <span v-if="paramshome.type == 'Condomínio'">Código do condominio ou parte do endereço</span>
                    <span v-if="paramshome.type == 'Construtora'">Código do construtora  ou parte do nome</span>
                    <!--span v-if="paramshome.type == 'Proprietario'">Parte do nome</span-->
                </span>
            </template>

            <v-tooltip slot="append" right>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="primary" dark @click="searchHome">
                        mdi-magnify
                    </v-icon>
                </template>
                
                <span>Pesquisa</span>
            </v-tooltip>   
        </v-text-field>


        <v-spacer />

        <router-link to="/favorites">
            <v-badge
                v-if="favorites && favorites.length > 0"
                overlap
                color="red"
                :content="favorites.length"
            >
            <v-icon large left class="mr-0 p-0">
                mdi-cards-heart-outline
            </v-icon>
            </v-badge>
            <v-icon v-else large left class="mr-0 p-0">
                mdi-cards-heart-outline
            </v-icon>
        </router-link>  

        <div class="mx-3"></div>  
    </v-app-bar>

    <v-main class="pa-4 d-print-none" style="background: #f1f4f6;">
        <router-view :key="$route.fullPath"></router-view>
    </v-main>

    <v-snackbar
        v-if="tenant[0] && tenant[0].pending_accounts.length > 0 && me && me.profile == 'Master'"
        v-model="snackbar.snackbar"
        :multi-line="snackbar.multiLine"
        timeout="-1"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">    
        <router-link :to="{ 
            name: 'admin.tenants.index',  
        }">
            <v-btn
                color="blue"
                text
            >
            pagar agora
            </v-btn>
        </router-link>    

        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar.snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
        v-if="tenant[0] && tenant[0].expired_accounts.length > 0 && me && me.profile == 'Master'"
        v-model="snackbar_expired.snackbar"
        :multi-line="snackbar_expired.multiLine"
        timeout="-1"
    >
      {{ snackbar_expired.text }}

      <template v-slot:action="{ attrs }">    
        <router-link :to="{ 
            name: 'admin.tenants.index',  
        }">
            <v-btn
                color="blue"
                text
                v-bind="attrs"
            >
            pagar agora
            </v-btn>
        </router-link>    

      </template>
    </v-snackbar>


    <v-footer app inset class="blue darken-3 d-print-none">
        <v-row dense>
            <v-col cols="12" class="text-center white--text caption">
                {{ new Date().getFullYear() }} — AYT Real State V {{ version }}
            </v-col>
        </v-row>
    </v-footer>

    <loading-component></loading-component>
    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
import { CSS } from '../config/config.js'
import MenuNavigationComponent from './MenuNavigationComponent.vue'
import LoadingComponent from '../layouts/LoadingComponent.vue'
import packageJson from '../../package.json'; 

export default {
    created() {
        this.loadTenant()
    },
    
    components: {
        MenuNavigationComponent,
        LoadingComponent,
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },

        favorites () {
            return this.$store.state.favorites.items.data
        },
    },

    data () {
        return {
            version: packageJson.version,

            snackbar: {
                multiLine: true,
                snackbar: true,
                text: `Existe uma mensalidade disponível para pagamento.`,
            },

            snackbar_expired: {
                multiLine: true,
                snackbar: true,
                text: `Existe uma mensalidade vencida.`,
            },

            paramshome: {
                type: 'Imóvel',
                filter_home: ''
            },
            
            items: {
                search_types: ['FAC','Imóvel','Condomínio','Construtora']
            },

            teste: null,

            drawer: null,
            css: CSS,
            mask: {
                phone: '(##) ####-####'
            }
        }
    },

    methods: {
        loadTenant(){
            this.$store.dispatch('loadTenants')    
        },

        redirect(lnk){
            this.$router.push({name: lnk.name})
        },

        searchHome(){
            var route_name = ''  
            var vuex_function = '' 
            if(this.paramshome.type == 'FAC'){
                route_name = 'admin.facs.index'
                vuex_function = 'loadFacs'
            }
            if(this.paramshome.type == 'Imóvel'){
                route_name = 'admin.properties.index'
                vuex_function = 'loadProperties'
            }
            if(this.paramshome.type == 'Condomínio'){
                route_name = 'admin.condominiums.index'
                vuex_function = 'loadCondominiums'
            }
            if(this.paramshome.type == 'Construtora'){
                route_name = 'admin.builders.index'
                vuex_function = 'loadBuilders'
            }

            if(this.$route.name != route_name && route_name != '' && vuex_function != ''){
                this.$router.push({name: route_name, params: {filter_home: this.paramshome.filter_home}}).catch(() => {});
            }else{
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: {filter_home: this.paramshome.filter_home}
                })
               //this.$store.dispatch(vuex_function, this.paramshome)
            }
        }
    }
}
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }

  .inblock {
      display: inline-block;
      margin-right: 1.0em;
  }

  .pre-formatted {
    white-space: pre;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

    #table_border {
        border-top: 1px solid #757575;
        border-bottom: 1px solid #757575;
        border-left: 1px solid #757575;
        border-right: 1px solid #757575;
    }

    #td_border {
        border-right: 1px solid #d0d0d0; 
    }

    .scroll {
        overflow-y: scroll
    }

    a:link {
        color: black;
        text-decoration: none;
    }

    a:visited {
        color: black;
        text-decoration: none;
    }

    a:hover {
        color: black;
        text-decoration: none;
    }

    a:active {
        color: black;
        text-decoration: none;
    }
</style>