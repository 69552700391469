<template>
    <div id="116">
        <alert-component v-if="pendingFiles.length === 0" :alert="alerts.compressphoto"></alert-component>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center">
                <vue-dropzone
                    v-if="pendingFiles.length === 0"
                    ref="myDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    @vdropzone-files-added="handleFilesAdded"
                ></vue-dropzone>

                <!-- Lista reordenável dos arquivos pendentes -->
                <div v-else>
                    <alert-component :alert="alerts.dragable"></alert-component>

                    <div class="text-right">
                        <v-btn small class="ma-2" color="error" @click="removeAllPendingFile" v-if="pendingFiles.length > 0 && !loadingSubmit">
                            Excluir todos arquivos
                        </v-btn>
                    </div>

                    <draggable
                        v-model="pendingFiles"
                        @end="onReorder"
                        class="draggable-container"
                    >
                        <div v-for="(item, index) in pendingFiles" :key="index" class="file-item">
                            <img :src="getFilePreview(item.file)" alt="Preview" class="file-preview" />
                            <div v-if="loadingSubmit" class="progress-bar">
                                <div class="progress" :style="{ width: item.progress + '%' }"></div>
                            </div>
                            <span v-if="loadingSubmit">{{ item.progress }}%</span>
                            <v-btn v-if="!loadingSubmit" color="error" @click="removeFile(index)" small class="font-weight-medium">Excluir</v-btn>
                        </div>
                    </draggable>
                </div>

                <!-- Botão para simular o envio -->
                <v-btn :loading="loadingSubmit"  :class="$css_button + ' ma-4 font-weight-medium'" color="primary" @click="simulateUpload" v-if="pendingFiles.length > 0">
                    Enviar Arquivos
                </v-btn>

            </v-col>
        </v-row>

        <div v-if="(object.items || object.items_condominium) && pendingFiles.length === 0">
        
        <v-row v-if="object.items && object.items.length > 0" class="mt-2 mb-2">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">    
                <v-btn
                    v-if="!editUpload"
                    dark
                    color="success"
                    @click="editUploads()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Editar fotos
                </v-btn>

                <span v-if="object.condominium_id && object.condominium_id != ''">
                    <span class="ma-2"></span>    
                    <v-btn
                        v-if="!exportarPhoto && !editUpload"
                        dark
                        color="black"
                        @click="exportarPhotos()"
                        :class="$css_button"
                        :disabled="button.sendPhotos"
                    >
                        exportar fotos
                    </v-btn>  
                </span>

                <span v-if="editUpload && !exportarPhoto">
                    <v-btn
                        dark
                        color="success"
                        @click="massUpdateUpload()"
                        :class="$css_button"
                        :disabled="button.sendPhotos"
                    >
                        Gravar
                    </v-btn> 
                </span>

                <span class="ma-2"></span>
                <v-btn
                    v-if="editUpload"
                    dark
                    color="blue-grey"
                    @click="revertProcess()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Reverter
                </v-btn> 
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-right">    
                <v-btn
                    dark
                    color="primary"
                    @click="confirmMassDelete()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Excluir fotos cadastradas
                </v-btn>       
            </v-col>
        </v-row>   

        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                 <v-expansion-panels  class="mb-3" v-if="object.items_condominium && object.items_condominium.length > 0">
                    <v-expansion-panel elevation="1">
                        <v-expansion-panel-header>
                        <span class="red--text ma-0">Fotos do condominio - Para alterar as fotos do condominio e necessário entrar no cadastro do condominio.</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col v-for="photo in object.items_condominium" :key="photo.id" cols="12" xs="3" sm="3" md="2" lg="2" xl="2">
                                    <v-avatar
                                        class="ma-2"
                                        color="#BDBDBD"
                                        width="150px"
                                        height="120px"
                                        tile
                                    >
                                        <v-img 
                                            :src="photo | urlUpload"
                                            :lazy-src="photo | urlUpload"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                                >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-avatar>
                                </v-col>
                            </v-row>       
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-card
                    class="mx-auto"
                    outlined
                    v-if="object.items && object.items.length > 0"
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="pagination"
                        class="elevation-1 table-striped"
                        :hide-default-footer="editUpload"
                    >
                        <template v-slot:[`item.photo`]="{ item }"> 
                            <v-avatar
                                class="ma-2"
                                color="#BDBDBD"
                                width="100px"
                                height="80px"
                                tile
                            >
                                <v-img 
                                    :src="item | urlUpload"
                                    :lazy-src="item | urlUpload"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-avatar>
                        </template>

                        <template v-slot:[`item.order`]="{ item }">
                            <v-text-field
                                class="mt-6"
                                type="number"
                                v-model="item.order"
                                outlined
                                dense
                                :class="$css_field"
                                :disabled="!editUpload || exportarPhoto"
                                :filled="!editUpload || exportarPhoto"  
                            >
                            </v-text-field>
                        </template>

                        <template v-slot:[`item.description`]="{ item }">
                            <v-text-field
                                class="mt-6"
                                v-model="item.description"
                                outlined
                                dense
                                :class="$css_field"
                                :disabled="!editUpload || exportarPhoto"
                                :filled="!editUpload || exportarPhoto"
                            >
                            </v-text-field> 
                        </template>

                        <template v-slot:[`item.delete`]="{ item }">
                            <v-checkbox
                                v-if="editUpload"
                                v-model="item.delete" 
                                :class="$css_field"
                            >
                            </v-checkbox>
                        </template>

                        <template v-slot:[`item.exportar`]="{ item }">
                            <v-checkbox
                                v-if="exportarPhoto"
                                v-model="item.exportar" 
                                :class="$css_field"
                            >
                            </v-checkbox>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>   
                                <v-tooltip left v-if="item.file">
                                    <template v-slot:activator="{ on, attrs }">  
                                        <v-icon @click="downloadImage(item.urlremoto, item.file)" v-bind="attrs" v-on="on">mdi-download</v-icon>                       
                                    </template>
                                    <span>DOWNLOAD</span>
                                </v-tooltip>   

                                <!--v-tooltip v-if="editUpload" left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="fileDestroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>EXCLUIR</span>
                                </v-tooltip-->
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center">
                <v-btn
                    v-if="editUpload && !exportarPhoto"
                    dark
                    color="success"
                    @click="massUpdateUpload()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Gravar
                </v-btn>

                <v-btn
                    v-if="exportarPhoto"
                    dark
                    color="black"
                    @click="exportCondoUpload()"
                    :class="$css_button"
                    :disabled="button.sendPhotos"
                >
                    Exportar fotos selecionadas para o condominio
                </v-btn> 
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialog.massdelete"
            transition="dialog-top-transition"
            max-width="600"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    Confirmação
                    <v-spacer></v-spacer>
                    <v-btn icon @click="confirmMassDelete()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row dense>
                        <v-col cols="12">
                            Tem certeza que deseja excluir todas as fotos?  

                            <div class="text-center">
                                <v-radio-group
                                    v-model="confirm_delete"
                                    row
                                >
                                    <v-radio
                                        label="Sim"
                                        value="Sim"
                                    ></v-radio>

                                    <v-radio
                                        label="Não"
                                        value="Não"
                                    ></v-radio>
                                </v-radio-group>   
                            </div>                         
                        </v-col>
                    </v-row>
                    <v-row v-if="confirm_delete == 'Sim'">
                        <v-col cols="12" class="text-right">
                            <v-btn
                                dark
                                color="primary"
                                @click="massDestroyUpload()"
                                :class="$css_button"
                            >
                                Excluir
                            </v-btn>  
                        </v-col>
                    </v-row>     
                </v-container> 
            </v-card>    
        </v-dialog>  

        <v-dialog
            v-model="dialog.progressbar"
            hide-overlay
            persistent
            width="500"
        >
            <v-card
                dark
            >
                <v-card-text>
                <span class="mb-2">Processando ...</span>   
                
                <v-progress-linear
                    v-model="progressbar.uploads"
                    height="25"
                    class="mt-3"
                >
                    <strong>{{ Math.ceil(progressbar.uploads) }}%</strong>
                </v-progress-linear>

                </v-card-text>
            </v-card>
        </v-dialog>  

        </div>
    </div>
</template>

<script>
import AlertComponent from '../alert/AlertComponent.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from "axios"
import draggable from "vuedraggable";


export default {
    components: {
        vueDropzone: vue2Dropzone,
        draggable,
        AlertComponent
    },

    props: {
        object: {
            require: true,
        },
    },

    computed: {
        tenant() {
            return this.$store.state.tenants.items.data    
        },
    },

    data (){
        return {
            alerts: {
                compressphoto: {
                    type: 'info',
                    icon: 'mdi-alert-outline',
                    messages: ['Sugerimos sempre compactar as imagens antes de realizar o upload das fotos. Voce poderá acessar o site https://imagecompressr.com e seguir os seguintes passos: <br /> 1  - Acesse o site <a href="https://imagecompressr.com" target="_blank">https://imagecompressr.com </a> <br /> 2 - Clique no botão "Select Files" e faça o upload das imagens <br /> 3 - Altere o parâmetro "Max Size" para 0,5 e clique no botão "Start compression" ']
                },

                dragable: {
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['Antes de realizar o upload, você pode ordenar as fotos arrastando']
                }
            },

            loadingSubmit: false,

            editUpload: false,
            exportarPhoto: false,

            pagination: 10,

            button: {
                sendPhotos: false
            },

            loader: '',

            acceptedFiles: [],
            
            confirm_delete: 'Não',

            params_delete: {},

            params_setphoto: {},

            dialog: {
                massdelete: false,
                progressbar: false
            },

            progressbar: {
                uploads: 0,
            },

            editItem:{},

            dropzoneOptions: {
                timeout: 0,
                parallelUploads: 1,
                url: "#", // URL fictícia, pois não estamos usando backend
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                maxFilesize: 5, // Tamanho máximo do arquivo em MB
                addRemoveLinks: true,
                autoProcessQueue: false, // Desativa o upload automático
                maxFiles: 30,
                resizeHeight: 1500,
                resizeQuality: 1,
                acceptedFiles: "image/*", // Aceita apenas imagens
                dictDefaultMessage: "<strong style='color: #000000; font-size: 120%;'>Clique aqui para inserir as imagens. Extensões válidas (JPG, PNG e WebP).</strong> <br /><br /><strong style='color: #CC0000;'> É sugerido enviar 30 fotos por vez. <br /> Para facilitar o envio de fotos diminua o tamanho das fotos antes de enviar. <br /> Em média os portais de propaganda permitem um número maximo de 30 fotos por imóvel (v3.0.0)</strong>",
            },
            pendingFiles: [], // Array para armazenar os arquivos pendentes

            headers: [
                { text: 'FOTO', value: 'photo' , sortable: false},
                { text: 'ORDEM', value: 'order' , sortable: true}, 
                { text: 'DESCRIÇĀO', value: 'description' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            photos: []
        }
    },

    methods: {

        changeOrder(){
            this.dropzoneOptions.params.order += 1
            //console.log(this.dropzoneOptions.params.order)
        },   

        removeAllFiles(){
            this.$refs.myVueDropzone.removeAllFiles()
        },

        confirmMassDelete(){    
            this.dialog.massdelete = !this.dialog.massdelete
        },

        destroy() {
             this.$refs.myVueDropzone.removeAllFiles(true)
        },

        massDestroyUpload(){
            this.params_delete.model = this.object.model
            this.params_delete.item_id = this.object.item_id
            this.$store.dispatch('massDestroyUpload', this.params_delete)
                        .then(response => {
                            this.$snotify.success('Fotos excluídas com sucesso!')
                            this.confirmMassDelete()
                            this.confirm_delete = 'Não'
                            this.setPhotoFinal()
                            this.$emit('callLoadItem', this.object.item_id)
                            this.dropzoneOptions.params.order = 1
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
            
        },

        save(item, count, total) {
           
            this.$store.dispatch('updatePhoto', item)
                        .then(response => {
                            this.progressbar.uploads = ((count + 1)/total) * 100
                            if((count + 1) == total){
                                this.revertProcess()
                                this.dialog.progressbar = false
                                this.progressbar.uploads = 0
                                this.setPhotoFinal()
                            }else{
                                this.massUpdate(count + 1)
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.dialog.progressbar = false
                            this.$snotify.error('Erro no cadastro')
                        })
                        
        },

        uploadSuccess(){
            this.setPhotoFinal()
            this.$snotify.success('Fotos enviadas com sucesso!')
            this.destroy()
            this.$emit('callLoadItem',this.object.item_id)
        },

        fileDestroy(file){
            this.$store.dispatch('destroyUpload', file.id)
                        .then(response => {
                            this.setPhotoFinal()   
                            this.$emit('callLoadItem',this.object.item_id)
                          
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Registro excluído com sucesso')
                        )
                     
        },

        async downloadImage(urlImage, nameImage) {
            
            let fileImage = urlImage.replace('.webp','.jpg');
            let name = nameImage.replace('.webp','.jpg');

            const blob = await (await fetch(fileImage)).blob();
        
            const url = URL.createObjectURL(blob);
            Object.assign(document.createElement('a'), { href: url, download: name })
                .click();
            URL.revokeObjectURL(url);
        },

        openCloseDialog(dialog) {
            if(dialog == 'progressbar'){
                this.dialog.progressbar = !this.dialog.progressbar
            }
        },

        editUploads(){
            this.editUpload = true
            this.pagination = 1000
            this.headers = [
                { text: 'FOTO', value: 'photo' , sortable: false},
                { text: 'ORDEM', value: 'order' , sortable: true}, 
                { text: 'DESCRIÇĀO', value: 'description' , sortable: false},  
                { text: 'EXCLUSÃO', value: 'delete' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false }
            ]
        },

        exportarPhotos(){
            this.editUpload = true
            this.exportarPhoto = true
            this.pagination = 1000
            this.headers = [
                { text: 'FOTO', value: 'photo' , sortable: false},
                { text: 'ORDEM', value: 'order' , sortable: true}, 
                { text: 'DESCRIÇĀO', value: 'description' , sortable: false},  
                { text: 'EXPORTAR', value: 'exportar' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false }
            ]
        },

        revertProcess(){
            this.editUpload = false
            this.exportarPhoto = false
            this.pagination = 10
            this.$emit('callLoadItem', this.object.item_id)
            this.headers = [
                { text: 'FOTO', value: 'photo' , sortable: false},
                { text: 'ORDEM', value: 'order' , sortable: true}, 
                { text: 'DESCRIÇĀO', value: 'description' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ]
        },

        massUpdateUpload(){
            this.$store.dispatch('massUpdateUpload', this.object)
                        .then(response => {
                            this.$emit('callLoadItem', this.object.item_id)
                            this.revertProcess()
                            this.$snotify.success('Fotos atualizadas com sucesso!')
                        })
                        .catch(error => {
                            console.log('Erro set fotos')
                        })
        },

        exportCondoUpload(){
            this.$store.dispatch('massExportCondoUploads', this.object)
                        .then(response => {
                            this.$emit('callLoadItem', this.object.item_id)
                            this.revertProcess()
                            this.$snotify.success('Fotos exportadas com sucesso!')
                        })
                        .catch(error => {
                            console.log('Erro set fotos')
                        })
        },

        setPhotoFinal(){
            this.params_setphoto.model = this.object.model
            this.params_setphoto.item_id = this.object.item_id
            //console.log(this.params_setphoto)    
             
            this.$store.dispatch('setPhotoFinal', this.params_setphoto)
                        .then(response => {
                            this.$emit('callLoadItem', this.object.item_id)
                            //console.log('set photo success')
                        })
                        .catch(error => {
                            console.log('Erro set fotos')
                        })
            
        },

        handleFilesAdded(files) {
            // Verifica se 'files' é um array
            const fileList = Array.isArray(files) ? files : Array.from(files);

            // Adiciona os arquivos à lista de pendentes com progresso inicial de 0%
            this.pendingFiles = [
                ...this.pendingFiles,
                ...fileList.map((file) => ({ file, progress: 0 })),
            ];
        },

        onReorder() {
            // Função chamada quando a ordem dos arquivos é alterada
            //console.log("Nova ordem dos arquivos:", this.pendingFiles);
        },
        
        getFilePreview(file) {
            // Retorna a URL temporária da imagem para visualização
            return file.dataURL || URL.createObjectURL(file);
        },

        async simulateUpload() {
            this.loadingSubmit = true;
            var order = this.object.items != '' && this.object.items != null ? this.object.items.length + 1 : 1;

            for (const item of this.pendingFiles) {
                const formData = new FormData();
                formData.append("file", item.file); // Adiciona o arquivo ao FormData
                formData.append("model", this.object.model);
                formData.append("item_id", this.object.item_id);
                formData.append("resize", "true");
                formData.append("order", order);
                formData.append("version", '3');
                formData.append("watermark", this.object.watermark);

                try {
                    // Realiza o upload do arquivo
                    await this.uploadFile(formData, item);
                    order += 1;
                } catch (error) {
                    console.error("Erro ao enviar o arquivo:", error);
                    alert(`Falha ao enviar o arquivo ${item.file.name}.`);
                }
            }

            // Limpa a lista de arquivos após o término do upload
            this.pendingFiles = [];
            this.setPhotoFinal();
            this.loadingSubmit = false;
            //console.log("Todos os arquivos foram enviados!");
            
        },

        uploadFile(formData, item) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_ROOT_API + "/api/uploads", formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("TOKEN_AUTH"),
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        // Calcula o progresso do upload
                        const progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                        );
                        item.progress = progress;
                    },
                })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },

        removeFile(index) {
            // Remove o arquivo da lista pelo índice
            this.pendingFiles.splice(index, 1);
            //console.log("Arquivo removido:", index);
        },

        removeAllPendingFile() {
            // Remove o arquivo da lista pelo índice
            this.pendingFiles = [];
            //console.log("Arquivo removido:", index);
        },
    }
}
</script>

<style scoped>
#dropzone {
border: 2px dashed #ccc;
padding: 20px;
text-align: center;
margin-bottom: 20px;
}

.draggable-container {
display: grid;
grid-template-columns: repeat(4, 1fr); /* 4 itens por linha no desktop */
gap: 10px; /* Espaçamento entre os itens */
padding: 10px; /* Espaçamento interno */
border: 1px solid #ccc; /* Borda opcional para melhorar a visualização */
}

@media (max-width: 1200px) {
.draggable-container {
    grid-template-columns: repeat(3, 1fr); /* 3 itens por linha em telas médias */
}
}

@media (max-width: 768px) {
.draggable-container {
    grid-template-columns: repeat(2, 1fr); /* 2 itens por linha em tablets */
}
}

@media (max-width: 480px) {
.draggable-container {
    grid-template-columns: 1fr; /* 1 item por linha em celulares */
}
}

.file-item {
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 10px;
}

.file-preview {
width: 150px; /* Ajuste o tamanho conforme necessário */
height: 150px;
object-fit: cover;
border: 1px solid #ddd; /* Borda opcional para destacar as imagens */
border-radius: 5px; /* Bordas arredondadas opcionais */
margin-bottom: 5px;
}

.progress-bar {
width: 100%;
height: 10px;
background-color: #f0f0f0;
border-radius: 5px;
overflow: hidden;
margin-top: 5px;
}

.progress {
height: 100%;
background-color: #4caf50;
transition: width 0.2s ease-in-out;
}
</style>
