<template>
    <div>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="outlined" :class="$css_button" depressed outlined @click.prevent="addRemoveFavorite">
                    <v-icon left v-bind="attrs" v-on="on">mdi-cards-heart-outline</v-icon> Fav.
                </v-btn>

                <v-icon v-else v-bind="attrs" v-on="on" :color="icon_color" @click.prevent="addRemoveFavorite">mdi-cards-heart-outline</v-icon>
            </template>
            <span>Favoritos</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: 'FavoriteButtonComponent',

    created(){
        this.checkFavorite()
    },

    props: {
        object: {
            require: false
        },

        outlined: {
            require: false,
            default: false
        }
    },

    data () {
        return {
            icon_color: 'blue-grey',
            limit: 5
        }
    },

    computed: {
        favorites () {
            return this.$store.state.favorites.items.data
        },

        tenant() {
            return this.$store.state.tenants.items.data    
        },
    },

    methods: {
        addRemoveFavorite(){
            var check = this.favorites.findIndex(search => search.id == this.object.id)
            if(check === -1){
                if(this.tenant[0].json_params && this.tenant[0].json_params.limit_favorites && this.tenant[0].json_params.limit_favorites > 0){
                    this.limit = this.tenant[0].json_params.limit_favorites
                }    

                if((this.favorites.length + 1) <= this.limit){
                    this.$store.commit('ADD_FAVORITE', this.object)
                    this.icon_color = 'red'
                    this.$snotify.success('Imovel adicionado nos favoritos com sucesso!')
                }else{
                     this.$snotify.error('Limite atingido')
                }
            }else{
                this.$store.commit('REMOVE_FAVORITE', check)
                this.icon_color = 'blue-grey'
                this.$snotify.success('Imovel excluido dos favoritos com sucesso!')
            }
        },

        checkFavorite(){
            var check = this.favorites.filter(search => search.id == this.object.id)
            if(check.length > 0){
                this.icon_color = 'red'
            }
        },

    },
}
</script>
