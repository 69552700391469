<template>
    <div>
        <v-card
            outlined
            id="table_border"
        >
            <v-simple-table dense>
                <thead fixed-header>
                    <tr>
                        <th>
                            <v-avatar
                                class="ma-0"
                                tile
                                width="50px"
                                height="50px"
                            >
                                <v-img 
                                    v-if="branch_logo && branch_logo != ''"
                                    :src="branch_logo"
                                    :lazy-src="branch_logo"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="lighten-5"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                                <v-icon v-else large dark>
                                    mdi-camera 
                                </v-icon>
                            </v-avatar>
                        </th>
                        <th class="text-h6 black--text">
                          Código {{ object.codigo }} -  {{ object.titulo_publicitario }}
                        </th>
                    </tr>
                </thead>
            </v-simple-table>
        </v-card>                                    
            
        <v-card
            outlined
            id="table_border"
        >
            <v-row dense v-if="object.photos_total && object.photos_total.length > 0">
                <v-col cols="12" md="4" v-for="(photo, index) in object.photos_total.slice(0, 6)" :key="index">
                    <v-avatar
                        class="ma-0"
                        color="#BDBDBD"
                        tile
                        width="100%"
                        :height="img.height"
                    >
                        <v-img 
                            :src="photo.urlremoto"
                            :lazy-src="photo.urlremoto"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                                >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-avatar>
                </v-col>
            </v-row>
        </v-card>

        <v-card
            class="pa-2 text-center"
            outlined
            id="table_border"
        >
            <span class="text-h6 black--text">
                <span v-if="object.dormitorios && object.dormitorios > 0"> Dormitório(s): {{ object.dormitorios }} <span v-if="object.suites">({{ object.suites }} suite(s))</span> </span>
                <span class="ml-3" v-if="object.banheiros && object.banheiros > 0"> Banheiro(s): {{ object.banheiros }}</span>
                <span class="ml-3" v-if="object.vagas && object.vagas > 0"> Vaga(s): {{ object.vagas }}</span>
                <span class="ml-3" v-if="object.area && object.area > 0"> Área: {{ object.area }} m²</span>
            </span>
        </v-card>

        <v-card
            class="pa-2 text-center"
            outlined
            id="table_border"
        >
            <span class="text-h6" v-if="object.valor_venda && object.valor_venda > 0">Venda {{ object.valor_venda | toCurrency }}</span>      
            <span  v-if="object.valor_venda && object.valor_venda > 0" class="mr-2"></span>

            <span class="text-h6" v-if="object.valor_locacao && object.valor_locacao > 0">Locaçāo {{ object.valor_locacao | toCurrency }}</span> 
            <span v-if="object.valor_locacao && object.valor_locacao > 0" class="mr-2"></span>

            <span class="text-h5 ml-3" v-if="object.valor_condominio">Condomínio {{ object.valor_condominio | toCurrency }}</span>      
        </v-card>
                            
    </div>
</template>

<script>

export default {    
    props: {
        object: {
            require: true,
        },

        branch_logo: {
            require: false,
        }
    },

    data () {
        return {
            modelo: 1,
            img:{
                height: '250px'
            }
        }   
    },

}
</script>

<style scoped>
#table_border {
    border-top: 1px solid #757575;
    border-bottom: 1px solid #757575;
    border-left: 1px solid #757575;
    border-right: 1px solid #757575;
    margin-bottom: 10px;
}
</style>